const CLIENT_CONFIG = {
  name: "Axerta",
  numTutorialImages: 13,
  showAvatarCampaigns: true,
  apiEndpoint: "https://api-axerta.whappy.it/api/",
  primaryColor: "#002c5f",
  primaryColor_2: "#002c5f",
  secondaryColor: "#c9b063",
  secondaryColor_2: "#c9b063",
  thirdaryColor: "#002c5f",
  thirdaryColor_2: "#002c5f",
  graphLightColor_1: "#0050ab",
  graphLightColor_2: "#efd176",
  graphDarkColor_1: "#00142b",
  graphDarkColor_2: "#96834a",
}
export default CLIENT_CONFIG
