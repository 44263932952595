<template>
  <ion-menu
    v-if="showMenu()"
    type="overlay"
    side="start"
    id="app-menu"
    menu-id="app-menu"
    content-id="main-content"
    v-bind:class="{ 'custom-menu-open': customMenuOpen }"
    @ionWillOpen="menuWillOpenHandle()"
    @ionDidClose="menuDidCloseHandle"
  >
    <ion-header v-if="menuVisible">
      <ion-toolbar translucent>
        <div class="menu-logo">
          <img
            class="menu-logo"
            :src="whappyClientAsset + 'img/logo.png'"
            @error="handleImgError"
          />
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="menuVisible">
      <ion-list>
        <ion-item
          @click="
            whappyClientConfig.name == 'Axerta'
              ? menuNavigation('/axerta/lista-ordini')
              : menuNavigation('/welcome')
          "
        >
          <ion-label>{{ $t("menu.home") }}</ion-label>
        </ion-item>
        <ion-item v-if="whappyClientConfig.name == 'Demo'">
          <ion-label @click="menuVisible = !menuVisible">
            Personal Set Up
          </ion-label>
        </ion-item>
        <ion-item
          @click="
            menuNavigation(getCampaignDefaultUrl(getOnBoardingCampaign()))
          "
          v-if="getOnBoardingId()"
        >
          <ion-label>{{ $t("menu.onboarding") }}</ion-label>
        </ion-item>
        <ion-item @click="menuNavigation('/account')">
          <ion-label>Profilo</ion-label>
        </ion-item>

        <ion-item
          v-if="whappyClientConfig.name == 'Yoube'"
          @click="menuNavigation('/lista-ordini/0')"
        >
          <ion-label>Lista Ordini</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name == 'Yoube'"
          @click="menuNavigation('/catalogo')"
        >
          <ion-label>Catalogo</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name == 'Yoube'"
          @click="menuNavigation('/stats/yoube')"
        >
          <ion-label>Statistiche rete</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name == 'Fattoria Dei Talenti'"
          @click="menuNavigation('/AnalisiDelTalento')"
        >
          <ion-label> Analisi del talento </ion-label>
        </ion-item>

        <ion-item
          @click="toggleClientiCollapsed"
          v-if="whappyClientConfig.name == 'Yoube'"
        >
          <div class="w-full flex justify-content-between align-items-center">
            <ion-label>Clienti</ion-label>
            <ion-icon
              v-if="!clientiCollapsed"
              class="no-bg p-0"
              :icon="chevronDownOutline"
            ></ion-icon>
            <ion-icon
              v-else
              class="no-bg p-0"
              :icon="chevronUpOutline"
            ></ion-icon>
          </div>
        </ion-item>

        <ion-item
          v-if="whappyClientConfig.name == 'Yoube' && clientiCollapsed"
          @click="menuNavigation('/anagraficaClienti')"
        >
          <ion-label class="pl-4"> I miei clienti</ion-label>
        </ion-item>

        <!-- <ion-item
          v-if="whappyClientConfig.name == 'Yoube' && clientiCollapsed"
          @click="menuNavigation('/anagraficaClientiCaldi')"
        >
          <ion-label class="pl-4">Clienti caldi</ion-label>
        </ion-item> -->
        <!-- <ion-item
          v-if="
            whappyClientConfig.name == 'Yoube' &&
            clientiCollapsed &&
            currentUser.role === 'Direzione commerciale'
          "
          @click="menuNavigation('/anagraficaClientiAssegnati')"
        >
          <ion-label class="pl-4">Clienti assegnati</ion-label>
        </ion-item> -->
        <ion-item
          v-if="whappyClientConfig.name == 'Yoube' && clientiCollapsed"
          @click="menuNavigation('/anagraficaFarmacieAssegnate')"
        >
          <ion-label class="pl-4">Farmacie assegnate</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name == 'Yoube' && clientiCollapsed"
          @click="menuNavigation('/anagraficaLeadCaldi')"
        >
          <ion-label class="pl-4">Lead caldi</ion-label>
        </ion-item>
        <!-- <ion-item
          v-if="settings.show_tutorial && whappyClientConfig.name != 'Yoube'"
          @click="menuNavigation('/tutorial')"
        >
          <ion-label>{{ $t("menu.tutorial") }}</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name != 'Yoube'"
          @click="menuNavigation('/faq')"
        >
          <ion-label>{{ $t("menu.faq") }}</ion-label>
        </ion-item>
        <ion-item
          v-if="whappyClientConfig.name != 'Yoube'"
          @click="menuNavigation('/report')"
        >
          <ion-label>{{ $t("menu.report_a_problem") }}</ion-label>
        </ion-item>

        <ion-item v-if="whappyClientConfig.name != 'Yoube'" @click="logout">
          <ion-label>{{ $t("menu.logout") }}</ion-label>
        </ion-item> -->

        <ion-item
          v-if="whappyClientConfig.name == 'Yoube'"
          @click="
            openLink(
              'https://calendar.google.com/calendar/u/1/r/month/2023/12/1?pli=1'
            )
          "
        >
          <ion-label>Calendario Ubeauty day </ion-label>
        </ion-item>
      </ion-list>
      <ion-grid class="ion-padding-top">
        <ion-row>
          <ion-col v-if="settings.show_tutorial"
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                @click="menuNavigation('/tutorial')"
                :icon="book"
                size="large"
                style="color: var(--ion-color-primary)"
              ></ion-icon>
            </ion-row>
            <div class="flex justify-content-center align-items-center pt-1">
              <span class="text-10">Tutorial</span>
            </div>
          </ion-col>
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                @click="menuNavigation('/faq')"
                :icon="helpCircle"
                size="large"
                style="color: var(--ion-color-primary)"
              ></ion-icon>
            </ion-row>
            <div class="flex justify-content-center align-items-center pt-1">
              <span class="text-10">FAQ App</span>
            </div></ion-col
          >
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                @click="menuNavigation('/report')"
                :icon="flag"
                size="large"
                style="color: var(--ion-color-primary)"
              ></ion-icon>
            </ion-row>
            <div class="flex justify-content-center align-items-center pt-1">
              <span class="text-10">Help</span>
            </div></ion-col
          >
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                @click="logout"
                :icon="logOut"
                size="large"
                style="color: var(--ion-color-primary)"
              ></ion-icon>
            </ion-row>
            <div class="flex justify-content-center align-items-center pt-1">
              <span class="text-10">Logout</span>
            </div></ion-col
          >
        </ion-row>
      </ion-grid>
      <p class="powered-by">
        Powered by<br /><img
          @click="whappyClick"
          src="/assets/img/logo-whappy-blue.png"
          @error="handleImgError"
        />
      </p>
    </ion-content>
    <ion-content v-else>
      <div
        style="
           {
            padding: 1rem 0.5rem,
            background: #0072a8,
            color: white,
            height: 100%,
            overflow-y: scroll,
          }
        "
      >
        <ion-row class="justify-content-around align-items-center">
          <ion-col size="auto">
            <ion-icon
              @click="menuVisible = !menuVisible"
              role="button"
              style="font-size: 2rem; padding-top: 1rem; color: white"
              name="close"
            ></ion-icon>
          </ion-col>
          <ion-col size="auto">
            <h2>Personal Setup</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <span style="font-size: 11px">
              Scegli i servizi per comporre liberamente il tuo piano di loyality
              per diventare Sandals Ambassador e partecipare gratuitamente ad
              uno dei nostri Fam Trip
            </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="personal-setup-list">
              <div
                v-for="(item, index) in personalSetupItems"
                :key="index"
                class="personal-setup-list-item"
              >
                <input
                  v-model="item.value"
                  type="checkbox"
                  @change="personalSetup_checkbox(item)"
                  class="personal-setup-list-item-checkbox"
                />
                <div
                  class="personal-setup-list-item-info"
                  @click="showItemInfo(item)"
                >
                  <span class="personal-setup-list-item-info-label">{{
                    item.label
                  }}</span>
                  <div class="personal-setup-list-item-info-perc">
                    {{ item.perc }}%
                  </div>
                  <span class="personal-setup-list-item-info-help">?</span>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row style="justify-content: center">
          <ion-col size="auto">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3rem;
                margin: 1rem;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                border: 1px solid white;
              "
            >
              <span id="personal-setup-totperc">0</span> <span>%</span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="personal-setup-bottomtext">
              <span> Questa è la percentuale di </span>
              <span class="personal-setup-bottomtext-numero">
                {{
                  premioTotale.toLocaleString("it", {
                    type: "currency",
                  })
                }}
                €
              </span>
              <span>
                raggiungimento per il tuo Fam Trip del valore di almeno
              </span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row style="display: flex; justify-content: center">
          <ion-col size="auto">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3rem;
              "
            >
              <span id="valore-finale">0 </span>
              <span> €</span>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <ion-footer v-if="whappyClientConfig.name == 'Yoube'">
      <div
        style="background: var(--ion-color-secondary)"
        class="flex justify-content-around align-items-center w-full text-26"
      >
        <span style="color: black">
          <ion-icon :icon="logoInstagram" @click="gotoInstagram"></ion-icon>
        </span>
        <span style="color: black">
          <ion-icon :icon="logoFacebook" @click="gotoFacebook"></ion-icon
        ></span>
        <span style="color: black">
          <ion-icon :icon="logoLinkedin" @click="gotoLinkedin"></ion-icon
        ></span>
      </div>
    </ion-footer>
  </ion-menu>

  <div
    v-if="showPersonalSetupModal"
    :class="{ 'show-modal': showPersonalSetupModal }"
    class="modal"
  >
    <div class="modal-content">
      <div class="title-container">
        <h4>{{ modalData.label }}</h4>
      </div>
      <div
        white-space:
        pre-line
        v-if="modalData.short_text"
        class="description-container"
      >
        {{ modalData.short_text }}
      </div>
      <div class="personalsetup-items">
        <span class="personalsetup-items-titolo">Informazione condivisa:</span>
        <div class="personalsetup-items-list">
          <div v-for="icon in modalData.available_items" :key="icon">
            <ion-icon
              class="personalsetup-items-list-icon"
              v-if="icon.icon == 'eye'"
              :icon="eye"
            ></ion-icon>
            <span class="personalsetup-items-list-label">{{ icon.label }}</span>
          </div>
        </div>
        <span class="personalsetup-items-titolo"
          >Informazione non condivisa:</span
        >
        <div class="personalsetup-items-list">
          <div v-for="icon in modalData.unavailable_items" :key="icon">
            <ion-icon v-if="icon.icon == 'eye'" :icon="eye"></ion-icon>
            <span>{{ icon.label }}</span>
          </div>
        </div>
      </div>
      <div style="white-space: pre-line" v-if="modalData.long_text">
        {{ modalData.long_text }}
      </div>

      <div style="display: flex; justify-content: flex-end">
        <ion-button
          @click="showPersonalSetupModal = false"
          class="ion-margin-top"
        >
          Close
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonContent,
  IonTitle,
  IonMenu,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonGrid,
  IonFooter,
} from "@ionic/vue";
import {
  ellipsisVertical,
  close,
  eye,
  home,
  today,
  cog,
  airplane,
  book,
  helpCircle,
  flag,
  chevronDownOutline,
  notificationsOutline,
  chevronUpOutline,
  logOut,
  logoInstagram,
  logoFacebook,
  logoLinkedin,
} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { menuController } from "@ionic/vue";
import CampaignService from "../common/mixins/Campaign";
import UserService from "../common/mixins/User";
import { useStore } from "vuex";
export default {
  props: {
    pageTitle: {},
    data: {
      type: Array,
      // required: true,
      default: () => [],
    },
  },
  data() {
    return {
      customMenuOpen: false,
      menuVisible: true,
      personalSetupItems: [
        {
          label: "Booking",
          perc: 1,
          value: false,
          short_text:
            "Questa funzione ti permetterà di guadagnare un ulteriore 1% sugli acquisti da te già fatti per questo prossimo semestre Il servizio ti permetterà di ricevere direttamente le prenotazioni dai nostri clienti ricevuti dai canali social e fissare per loro un incontro con trattamento gratuito o farli convergere in una evento “OPE DAY” qualora tu abbiamo attivato anche questa funzione. La percentuale di sconto verrà aggiunta ai tuoi accordi commerciali già acquisiti solo per quelle farmacie che avranno implementato realmente questo servizio. Il vantaggio di sconto verrà ripartito i percentuali differenti sotto indicate fra te e il farmacista che aderirà alla tua promozione. Il sistema permetterà alle farmacie di guadagnare punti e medaglie e partecipare alla promozione tramite le loro banconiste, premiandole.",
          available_items: [
            {
              icon: "eye",
              label: "Codice ID anonimo Utente",
            },
            {
              icon: "eye",
              label: "Codice ID anonoimo Negozio",
            },
            {
              icon: "eye",
              label: "Codice ID Data e Ora Booking",
            },
          ],
          unavailable_items: [
            {
              icon: "eye",
              label: "Itentità reale Utente",
            },
            {
              icon: "eye",
              label: "Identità reale Utente",
            },
          ],
          long_text:
            "- Cosa prevede esattamente l’ accordo che stai accettando? \n \n Spiegazione dell’ accordo loden IPSUM  Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM \n \n \n - Come viene cotrattualizzato nella GDPR: \n \n Spiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM",
        },
        {
          label: "Academy",
          perc: 3,
          value: false,
          short_text:
            "Questa funzione ti permetterà di guadagnare un ulteriore 2% sui percorsi formativi che riuscirai a far fare alle tue farmacie.Sarà sufficiente che ottengano la certificazione di formazione avvenuta tra i loro venditori per ottenere un ulteriore premio del 2% della merce acquistata  nel semestre in corso.(segnalo a Max che quindi qui va deciso a sua volta quanto il farmacista vuole ripartire del suo premio alle venditrici). Il sistema permetterà alle farmacie di guadagnare punti e medaglie e partecipare alla promozione tramite le loro banconiste, premiandole.",
          available_items: [
            {
              icon: "eye",
              label: "Codice ID anonimo Utente",
            },
            {
              icon: "eye",
              label: "Codice ID anonoimo Negozio",
            },
            {
              icon: "eye",
              label: "Codice ID Data e Ora Booking",
            },
          ],
          unavailable_items: [
            {
              icon: "eye",
              label: "Itentità reale Utente",
            },
            {
              icon: "eye",
              label: "Identità reale Utente",
            },
          ],
          long_text:
            "- Cosa prevede esattamente l’ accordo che stai accettando? \n \n Spiegazione dell’ accordo loden IPSUM  Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM \n \n \n - Come viene cotrattualizzato nella GDPR: \n \n Spiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM",
        },
        {
          label: "Lead Generation",
          perc: 2,
          value: false,
          short_text:
            "Questa funzione ti permette riottenere gratis le persone che nelle prossimità della farmacia tramite il nostro sito chiedo informazione. Clienti freschi a costo zero, devi prenderti solo l’impegno di risponderli e gestirli con la nostra app.Il sistema permetterà alle farmacie di guadagnare punti e medaglie e partecipare alla promozione tramite le loro banconiste, premiandole.",
          available_items: [
            {
              icon: "eye",
              label: "Codice ID anonimo Utente",
            },
            {
              icon: "eye",
              label: "Codice ID anonoimo Negozio",
            },
            {
              icon: "eye",
              label: "Codice ID Data e Ora Booking",
            },
          ],
          unavailable_items: [
            {
              icon: "eye",
              label: "Itentità reale Utente",
            },
            {
              icon: "eye",
              label: "Identità reale Utente",
            },
          ],
          long_text:
            "- Cosa prevede esattamente l’ accordo che stai accettando? \n \n Spiegazione dell’ accordo loden IPSUM  Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM \n \n \n - Come viene cotrattualizzato nella GDPR: \n \n Spiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM",
        },
        {
          label: "Ambassador",
          perc: 2,
          value: false,
          short_text:
            "Questa funzione permette ad ogni farmacia di farsi pubblicità direttamente tramite la nostra applicazione, sarà sufficiente che le banconiste o promoter facciano condivisioni sui social delle promozioni e dei post e automaticamente riceveranno nuovi clienti. I vantaggi sono di utilizzare di post , promozioni e inviti già pronti all’uso creati da YouBe che convertiranno e premieranno direttamente il farmacista che direttamente o tramite le sue banconiste veicolerà i contenuti ai propri clienti e sui social. Il sistema permetterà alle farmacie di guadagnare punti e medaglie e partecipare alla promozione tramite le loro banconiste, premiandole",
          available_items: [
            {
              icon: "eye",
              label: "Codice ID anonimo Utente",
            },
            {
              icon: "eye",
              label: "Codice ID anonoimo Negozio",
            },
            {
              icon: "eye",
              label: "Codice ID Data e Ora Booking",
            },
          ],
          unavailable_items: [
            {
              icon: "eye",
              label: "Itentità reale Utente",
            },
            {
              icon: "eye",
              label: "Identità reale Utente",
            },
          ],
          long_text:
            "- Cosa prevede esattamente l’ accordo che stai accettando? \n \n Spiegazione dell’ accordo loden IPSUM  Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM \n \n \n - Come viene cotrattualizzato nella GDPR: \n \n Spiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM",
        },
        {
          label: "Open Day",
          perc: 1,
          value: false,
          short_text:
            "Questa funzione permette ad ogni farmacia di avere un evento mensile di promozione sponsorizzato e supportato da YouBe. L’evento consiste nel dirottare i potenziali clienti ottenuti dalle prenotazioni d Booking, lead generati dal web e dalla pubblicità in un unico giorno mettendo a disposizione una promoter YouBe dedicata. Il vantaggio è che ogni farmacista potrà cosi capitalizzare tutte le attività promozionali in un solo giorno creando traffico nella farmacia e al tempo stesso creando una linea dedicata di alto livelloper le clienti. I vantaggi sono evidenti, maggiore conversione di clienti e un Hyoe sull’evento che verrà ulteriormente supportato con materiali, e pomozione web. Il sistema permetterà alle farmacie di guadagnare punti e medaglie e partecipare alla promozione tramite le loro banconiste, premiandole.",
          available_items: [
            {
              icon: "eye",
              label: "ID anonimo Utente",
            },
            {
              icon: "eye",
              label: "ID anonoimo Negozio",
            },
            {
              icon: "eye",
              label: "ID Data e Ora Booking",
            },
          ],
          unavailable_items: [
            {
              icon: "eye",
              label: "Itentità reale Utente",
            },
            {
              icon: "eye",
              label: "Identità reale Utente",
            },
          ],
          long_text:
            "- Cosa prevede esattamente l’ accordo che stai accettando? \n \n Spiegazione dell’ accordo loden IPSUM  Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM \n \n \n - Come viene cotrattualizzato nella GDPR: \n \n Spiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM Spiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUMSpiegazione dell’ accordo loden IPSUM",
        },
      ],
      personalSetup_totPerc: 0,
      personalSetup_valoreFinale: 0,
      showPersonalSetupModal: false,
      modalData: null,
      premioTotale: 15000,
      clientiCollapsed: false,
    };
  },
  computed: {},
  mixins: [CampaignService, UserService],
  components: {
    IonRow,
    IonCol,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonMenu,
    IonLabel,
    IonItem,
    IonList,
    IonContent,
    IonMenuButton,
    IonGrid,
    IonFooter,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters["user/getUserData"];

    return {
      currentUser,
      ellipsisVertical,
      close,
      eye,
      home,
      today,
      cog,
      airplane,
      book,
      helpCircle,
      flag,
      logOut,
      chevronDownOutline,
      notificationsOutline,
      chevronUpOutline,
      logoInstagram,
      logoFacebook,
      logoLinkedin,
    };
  },
  methods: {
    ...mapGetters("campaigns", {
      getOnBoardingId: "getOnBoardingId",
      getOnBoardingCampaign: "getOnBoardingCampaign",
    }),
    ...mapActions("auth", {
      logoutUser: "logoutUser",
    }),
    openLink(link) {
      window.open(link, "_blank");
    },
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },

    gotoInstagram() {
      window.open("https://www.instagram.com/yoube.privateskin/", "_blank");
    },

    gotoFacebook() {
      window.open("https://www.facebook.com/yoube.privateskin", "_blank");
    },

    gotoLinkedin() {
      window.open(
        "https://www.linkedin.com/company/yoube-cosmetics/",
        "_blank"
      );
    },

    toggleClientiCollapsed() {
      this.clientiCollapsed = !this.clientiCollapsed;
    },

    showItemInfo(event) {
      this.showPersonalSetupModal = true;
      this.modalData = { ...event };
    },
    personalSetup_checkbox(event) {
      let start = this.personalSetup_totPerc;

      if (event.value) {
        this.personalSetup_totPerc = this.personalSetup_totPerc + event.perc;
      } else {
        this.personalSetup_totPerc = this.personalSetup_totPerc - event.perc;
      }

      this.animateValue(
        "personal-setup-totperc",
        start,
        this.personalSetup_totPerc,
        200
      );

      let valoreFinaleStart = this.personalSetup_valoreFinale;

      this.personalSetup_valoreFinale = (
        (this.premioTotale / 100) *
        this.personalSetup_totPerc
      ).toFixed(0);

      this.animateValue(
        "valore-finale",
        valoreFinaleStart,
        this.personalSetup_valoreFinale,
        200
      );
    },

    animateValue(id, start, end, duration) {
      console.log("trigg animate value ", id, start, end);
      start = parseInt(start);
      end = parseInt(end);
      if (start === end) return;
      var range = (end - start) * 10;
      var current = start;
      var increment = end > start ? 1 : -1;
      var stepTime = Math.abs(Math.floor(duration / range));
      var obj = document.querySelector("#" + id);
      var timer = setInterval(function () {
        console.log("in timer , ", current, increment, stepTime);
        current = parseInt(current) + increment;
        obj.innerHTML = current;
        if (current == end) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    whappyClick() {
      this.$root.externalLinkBlank("https://www.whappy.it/");
    },
    showMenu() {
      return this.isLogged();
    },
    menuWillOpenHandle() {
      this.customMenuOpen = true;
    },
    menuDidCloseHandle() {
      this.customMenuOpen = false;
    },
    menuNavigation(url) {
      menuController.close("app-menu");
      this.$router.push(url);
    },
    async logout() {
      await this.logoutUser();
      window.location.reload();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
ion-toolbar {
  color: var(--ion-color-primary);
}

ion-button.sign-out {
  font-size: 17px;
  font-weight: 500;
  margin-left: 5px;
  float: left;
  --color: #ffffff;
}

.ellipsis {
  color: #ffffff;
}

ion-title.logo {
  font-weight: 900;
  font-size: 25px;
}

ion-menu {
  &::part(container) {
    transition: all 0.1s ease-in;
    left: -304px;
  }

  &.custom-menu-open::part(container) {
    left: 0;
  }
}

.menu-logo {
  text-align: center;

  img {
    max-height: 100px;
    padding: 5px 5px 0 5px;
    margin: 0 auto;
  }
}

.powered-by {
  display: block;
  margin: 40px 0;
  font-size: 12px;
  padding: 16px;
  text-align: start;

  img {
    margin-top: 20px;
    margin-left: 50px;
    max-height: 30px;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 2rem 1.5rem;
  width: 95%;
  height: 80%;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr repeat(2, 2fr) 5fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow-y: scroll;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.personalsetup-items {
  width: 95%;
  padding: 1rem 2rem;
  margin: 1rem auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  &-titolo {
    font-weight: bold;
  }
  &-list {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ion-icon {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    &-label {
      font-size: 1rem;
    }
  }
}

ion-icon {
  border: 1px solid var(--ion-color-primary);
  border-radius: 50%;
  background: var(--ion-color-primary);
  color: #fff !important;
  padding: 0.6rem;
}

.no-bg {
  background: none;
  border: none;
  color: black !important;
}
</style>
